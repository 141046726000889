import { Typography } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import { AppLogo } from "./AppLogo";

interface INavDrawerHeaderProps {
  onClick: () => void;
  expanded: boolean;
}

export default function NavDrawerHeader({ onClick, expanded }: INavDrawerHeaderProps) {
  const theme = useTheme();

  return (
    <ListItem disablePadding sx={{ display: "block" }}>
      <ListItemButton
        onClick={onClick}
        sx={{
          justifyContent: expanded ? "initial" : "center",
          px: 2.5,
          py: 1.5,
          fontWeight: "bold",
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: expanded ? 2 : "auto",
            justifyContent: "center",
          }}
        >
          <AppLogo />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="h6" className="FontSpecial" >
              <b>{theme.app.name}</b>
            </Typography>
          }
          secondary={<Typography variant="body2">{theme.app.caption}</Typography>}
          sx={{
            opacity: expanded ? 1 : 0,
            color: theme.app.nav.textColor,
            "& .MuiListItemText-secondary": { color: theme.app.nav.textColor },
          }}
        />
      </ListItemButton>
    </ListItem>
  );
}
