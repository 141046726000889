import { Chip, Stack } from "@mui/material";
import { FC } from "react";
import { ITagRead } from "./tagTypes";
import TagIcon from '@mui/icons-material/Tag';
interface TagsListProps {
  tags: ITagRead[];
}

export const TagsList: FC<TagsListProps> = ({ tags }) => {
  return (
    <Stack direction={"row"} flexWrap={"nowrap"} overflow={"auto"} spacing={1}>
      {tags.map((tag) => (
        <Chip key={tag.id} color={"info"} label={typeof tag === "string" ? tag : tag.name} variant={"outlined"} size="small" icon={<TagIcon />} />
      ))}
    </Stack>
  );
};
