import { useCreateUserMutation, useGetUserQuery, useUpdateUserMutation, useGetOrganizationQuery } from "@app/services/appApi";
import useAuthContext from "@core/auth/AuthContext";
import { IUserCreate, IUserRead, IUserUpdate } from "@core/auth/types";
import OrganizationAutocomplete from "@features/organization/OrganizationAutocomplete";
import { yupResolver } from "@hookform/resolvers/yup";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { stopPropagate } from "@utils/form";
import { TFunction } from "i18next";
import { enqueueSnackbar } from "notistack";
import { ComponentProps, FC, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { number, object, string } from "yup";

const DEFAULT_CREATE_VALUES: IUserCreate = {
  username: "",
  organization_id: "",
  password: "",
  role: "USER",
};

const cleanReadUserDataForUpdate = (user: IUserRead): IUserUpdate => {
  const { username, organization, id, role } = user;
  return { username, organization_id: organization.id, id, role, password: "" };
};

interface IUserFormDialogProps extends ComponentProps<typeof Dialog> {
  userId?: IUserRead["id"];
}

const schema = (t: TFunction) =>
  object({
    id: number(),
    username: string()
      .required(t("validation.requiredField"))
      .min(3, t("validation.minLength", { count: 3 }))
      .email(t("validation.email.invalid")),
    organization_id: string().required(t("validation.requiredField")),
    password: string().when("id", {
      is: undefined,
      then: () =>
        string()
          .required(t("validation.requiredField"))
          .min(3, t("validation.minLength", { count: 3 })),
      otherwise: () =>
        string().when("password", {
          is: "",
          then: () => string().notRequired(),
          otherwise: () =>
            string()
              .required()
              .min(3, t("validation.minLength", { count: 3 })),
        }),
    }),
    role: string().oneOf(["USER", "ADMIN"]).required(t("validation.requiredField")),
  }).required(t("validation.requiredField"));

const UserFormDialog: FC<IUserFormDialogProps> = ({ userId, ...props }) => {
  const createMode = !userId;

  const { t } = useTranslation();
  const authContext = useAuthContext();
  const { data: organizationData } = useGetOrganizationQuery(authContext.user?.organization.id ?? skipToken);


  const { control, handleSubmit, reset } = useForm<IUserCreate | IUserUpdate>({
    // @ts-ignore
    resolver: yupResolver(schema(t)),
  });

  // Delay data fetching until userId is defined and dialog is opened
  const { data: user, isLoading: isLoadingUsers } = useGetUserQuery(userId ?? skipToken, { skip: !props.open });
  const [
    triggerCreate,
    { isLoading: isCreatingUser, isSuccess: isCreateSuccess, isError: isCreateError, reset: resetCreate },
  ] = useCreateUserMutation();
  const [
    triggerUpdate,
    { isLoading: isUpdatingUser, isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate },
  ] = useUpdateUserMutation();

  const canPickOrganization =
    authContext.user?.organization.max_child_orgs !== undefined && authContext.user?.organization.max_child_orgs > 0;
  const isLoading = isCreatingUser || isUpdatingUser || isLoadingUsers;

  useEffect(() => {
    if (user) {
      reset(cleanReadUserDataForUpdate(user));
    } else {
      reset(DEFAULT_CREATE_VALUES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, props.open, reset]);

  const sendData = (data: IUserCreate | IUserUpdate) => {
    const organizationId = canPickOrganization ? data.organization_id : (authContext.user?.organization.id ?? "-1");

    if (createMode) {
      triggerCreate({ ...data, organization_id: organizationId });
    } else {
      triggerUpdate({
        id: userId || -1,
        ...data,
        organization_id: organizationId,
      });
    }
  };

  const handleClose = useCallback(
    (event: {}, reason: "escapeKeyDown" | "backdropClick") => {
      if (reason === "backdropClick") return; // Ignora il click fuori dal dialog
      props.onClose?.(event, reason);
    },
    [props],
  );

  // Handle error and success notifications
  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar(t("notifications.modelCreate.failure", { model: t("models.user") }), { variant: "error" });
    }
    if (isCreateSuccess) {
      enqueueSnackbar(t("notifications.modelCreate.success", { model: t("models.user") }), { variant: "success" });
      handleClose({}, "escapeKeyDown");
      resetCreate();
    }
    if (isUpdateError) {
      enqueueSnackbar(t("notifications.modelUpdate.failure", { model: t("models.user") }), { variant: "error" });
    }
    if (isUpdateSuccess) {
      enqueueSnackbar(t("notifications.modelUpdate.success", { model: t("models.user") }), { variant: "success" });
      handleClose({}, "escapeKeyDown");
      resetUpdate();
    }
  }, [handleClose, isCreateError, isCreateSuccess, isUpdateError, isUpdateSuccess, resetCreate, resetUpdate, t]);


  return (
    <Dialog fullWidth maxWidth="sm" {...props} onClose={handleClose}>
      <DialogTitle>
        {createMode ? t("users.formDialog.createTitle") : t("users.formDialog.editTitle")}
      </DialogTitle>
      {!isLoadingUsers ? (
        <form onSubmit={stopPropagate(handleSubmit(sendData))}>
          <DialogContent>
            <Stack gap={2}>
              <Controller
                name={"username"}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    autoFocus
                    fullWidth
                    required
                    label={t("users.formDialog.email.label")}
                    type="text"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
              {canPickOrganization && (
                <Controller
                  name={"organization_id"}
                  control={control}
                  render={({ field, fieldState }) => (
                    <OrganizationAutocomplete
                      inputProps={{
                        error: !!fieldState.error,
                        helperText: fieldState.error?.message,
                        label: t("users.formDialog.organization.label"),
                        required: true,
                      }}
                      autocompleteProps={{
                        fullWidth: true,
                        ...field,
                      }}
                    />
                  )}
                />
              )}
              <Controller
                name={"password"}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    required={createMode}
                    label={t("users.formDialog.password.label")}
                    type="password"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
              <Controller
                name={"role"}
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <InputLabel id="role-select">{t("users.formDialog.role.label")}</InputLabel>
                    <Select
                      labelId="role-select"
                      label={t("users.formDialog.role.label")}
                      error={!!fieldState.error}
                      {...field}
                    >
                      <MenuItem value={"ADMIN"}>{t("users.formDialog.role.admin")}</MenuItem>
                      <MenuItem value={"USER"}>{t("users.formDialog.role.user")}</MenuItem>
                    </Select>
                    {fieldState.error && <FormHelperText>{fieldState.error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ m: 2 }}>
            <Button startIcon={<CloseOutlinedIcon />} onClick={(event) => handleClose(event, "escapeKeyDown")}>
              {t("notes.formDialog.cancel")}
            </Button>
            <LoadingButton
              startIcon={<CheckOutlinedIcon />}
              loading={isLoading}
              disabled={isLoading || (createMode && !organizationData?.has_available_user_slots)}
              variant="contained"
              type="submit"
            >
              {createMode ? t("users.formDialog.create") : t("users.formDialog.update")}
            </LoadingButton>
          </DialogActions>
        </form>
      ) : (
        <DialogContent>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
};


export default UserFormDialog;
