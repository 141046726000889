import { useCreateTagMutation, useGetTagQuery, useUpdateTagMutation } from "@app/services/appApi";
import { yupResolver } from "@hookform/resolvers/yup";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { stopPropagate } from "@utils/form";
import { TFunction } from "i18next";
import { enqueueSnackbar } from "notistack";
import { ComponentProps, FC, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import { ITagCreate, ITagRead, ITagUpdate } from "./tagTypes";

const DEFAULT_CREATE_VALUES: ITagCreate = {
  name: "",
};

const cleanReadTagDataForUpdate = (tag: ITagRead): ITagUpdate => {
  const { name } = tag;
  return { name };
};

interface ITagFormDialogProps extends ComponentProps<typeof Dialog> {
  tagId?: ITagRead["id"];
  initialValues?: ITagCreate | ITagUpdate;
  onCreate?: (tag: ITagRead) => void;
  onUpdate?: (tag: ITagRead) => void;
}

const schema = (t: TFunction) =>
  object({
    name: string()
      .required(t("validation.requiredField"))
      .min(3, t("validation.minLength", { count: 3 })),
  }).required(t("validation.requiredField"));

const TagFormDialog: FC<ITagFormDialogProps> = ({ tagId, initialValues, onCreate, onUpdate, ...props }) => {
  const createMode = !tagId;

  const { t } = useTranslation();

  const { control, handleSubmit, reset } = useForm<ITagCreate | ITagUpdate>({ resolver: yupResolver(schema(t)) });

  // Delay data fetching until tagId is defined and dialog is opened
  const { data: tag, isLoading: isDataLoading } = useGetTagQuery(tagId ?? skipToken, { skip: !props.open });
  const [
    triggerCreate,
    {
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
      data: createdTag,
      reset: resetCreate,
    },
  ] = useCreateTagMutation();
  const [
    triggerUpdate,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      data: updatedTag,
      reset: resetUpdate,
    },
  ] = useUpdateTagMutation();

  const isLoading = isCreateLoading || isUpdateLoading || isDataLoading;

  useEffect(() => {
    if (tag) {
      reset(cleanReadTagDataForUpdate(tag));
    } else {
      reset(DEFAULT_CREATE_VALUES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag, props.open]);

  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  }, [initialValues, reset]);

  const sendData = (data: ITagCreate | ITagUpdate) => {
    if (createMode) {
      triggerCreate(data);
    } else {
      triggerUpdate({
        id: tagId || "",
        ...data,
      });
    }
  };

  const handleClose = useCallback(
    (event: {}, reason: "escapeKeyDown" | "backdropClick") => {
      if (reason === "backdropClick") return; // Ignora il click fuori dal dialog
      props.onClose?.(event, reason);
    },
    [props],
  );

  // Handle error and success notifications
  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar(t("notifications.modelCreate.failure", { model: t("models.tag") }), {
        variant: "error",
      });
    }
    if (isCreateSuccess) {
      enqueueSnackbar(t("notifications.modelCreate.success", { model: t("models.tag") }), {
        variant: "success",
      });
      handleClose({}, "escapeKeyDown");
      onCreate?.(createdTag);
      resetCreate();
    }
    if (isUpdateError) {
      enqueueSnackbar(t("notifications.modelUpdate.failure", { model: t("models.tag") }), {
        variant: "error",
      });
    }
    if (isUpdateSuccess) {
      enqueueSnackbar(t("notifications.modelUpdate.success", { model: t("models.tag") }), {
        variant: "success",
      });
      handleClose({}, "escapeKeyDown");
      onUpdate?.(updatedTag);
      resetUpdate();
    }
  }, [
    createdTag,
    handleClose,
    isCreateError,
    isCreateSuccess,
    isUpdateError,
    isUpdateSuccess,
    onCreate,
    onUpdate,
    resetCreate,
    resetUpdate,
    t,
    updatedTag,
  ]);

  return (
    <Dialog fullWidth maxWidth="sm" {...props} onClose={handleClose}>
      <DialogTitle>{createMode ? t("tags.formDialog.creation.title") : t("tags.formDialog.update.title")}</DialogTitle>
      {!isDataLoading ? (
        <form onSubmit={stopPropagate(handleSubmit(sendData))}>
          <DialogContent>
            <Stack gap={2}>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    autoFocus
                    fullWidth
                    label={t("tags.formDialog.tag.label")}
                    type="text"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ m: 2 }}>
            <Button startIcon={<CloseOutlinedIcon />} onClick={(event) => handleClose(event, "escapeKeyDown")}>
              {t("notes.formDialog.cancel")}
            </Button>
            <LoadingButton
              startIcon={<CheckOutlinedIcon />}
              loading={isLoading}
              disabled={isLoading}
              variant="contained"
              type="submit"
            >
              {createMode ? t("tags.formDialog.creation.confirm") : t("tags.formDialog.update.confirm")}
            </LoadingButton>
          </DialogActions>
        </form>
      ) : (
        <DialogContent>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default TagFormDialog;
