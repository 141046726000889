import SendIcon from "@mui/icons-material/Send";
import { Box, IconButton, InputAdornment, styled, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAssistantContext } from "../useAssistantContext";
import ChatColumnHeader from "./ChatColumnHeader";
import ChatMessageList from "./ChatMessageList";
import { useChat } from "./useChat";
import { CHAT_BACKGROUND_COLOR, CHAT_BORDER_COLOR, CHAT_MESSAGE_COLOR } from "./utils";

interface IChatColumnProps {
  close: () => void;
  open: () => void;
}

const MessageEditor = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: 12,
    border: `1px solid ${CHAT_BORDER_COLOR}`,
    backgroundColor: CHAT_MESSAGE_COLOR,
    width: '90%',
    margin: '24px 24px',
    transition: 'all 0.2s ease',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',

    '&:hover, &:focus-within': {
      border: `2px solid ${theme.palette.primary.main}`,
      boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
    },
  },
  "& fieldset": {
    border: 0,
  },

  "& input": {
    padding: '12px 16px',
    fontSize: '1rem',

    [theme.breakpoints.up("sm")]: {
      padding: '14px 16px',
    },
    [theme.breakpoints.up("md")]: {
      padding: '16px 32px',
    },
    [theme.breakpoints.up("lg")]: {
      padding: '16px 32px',
    },
    [theme.breakpoints.up("xl")]: {
      padding: '16px 32px',
    },
  },

  "& .MuiInputAdornment-root": {
    marginRight: 8,

    "& .MuiIconButton-root": {
      padding: 8,
      marginRight: 8,
    },

    "& svg": {
      color: theme.palette.text.secondary,
      fontSize: 24,
      transition: 'color 0.3s ease',
    },

    "&:hover svg": {
      color: theme.palette.primary.main,
    }
  }
}));

export const ChatColumn = ({ close, open }: IChatColumnProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  const { relevDeviceId: relevantDeviceId } = useAssistantContext();

  const {
    isChatUninitialized,
    resetChat,
    sendMessage,
    chat,
    websocketOpen,
    chatError,
    errorMessageId,
    errorMessage,
    clearErrorAndStartNewChat
  } = useChat({
    relevantDeviceId,
  });

  const [message, setMessage] = useState("");

  const handleMessageSumbit = useCallback(
    (body: string) => {
      if (!relevantDeviceId) {
        enqueueSnackbar(t("assistant.selectDeviceError"), { variant: "warning" });
        return;
      }
      setMessage("");
      open();
      sendMessage(body);
    },
    [open, relevantDeviceId, sendMessage, t],
  );


  useEffect(() => {
    const focusInput = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };
    focusInput();
    const timeoutId = setTimeout(focusInput, 100);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [chat, relevantDeviceId]);


  // Auto-trigger the chat if the ?startchat= query parameter is present. This is needed to launch the assistant from a link.
  // Removes the query parameter from the URL after opening the chat.
  // This is triggered only if component is mounted, so app must ensure the component is shown when the parameter is present.
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has("startchat")) {
      // Params are present, start the chat
      handleMessageSumbit(params.get("startchat") || "");
      // Remove the startchat parameter from the URL to prevent re-triggering the chat
      params.delete("startchat");
      navigate({ search: params.toString() });
    }
  }, [handleMessageSumbit, location.search, navigate]);

  return (
    <Box
      sx={{
        backgroundColor: CHAT_BACKGROUND_COLOR,
        height: "100%",
        width: {
          xs: "100%",
          sm: "100%",
          md: 600,
          lg: 600,
          xl: 600,
        },
        maxWidth: {
          xs: "100vw",
          sm: "100vw",
          md: 600,
          lg: 600,
          xl: 600,
        },
        display: "flex",
        flexDirection: "column",
        boxShadow: (theme) => `0 0 70px ${theme.palette.background.default}`,

      }}
    >
      <ChatColumnHeader chat={chat} isChatUninitialized={isChatUninitialized} onClose={close} resetChat={resetChat} />
      <ChatMessageList
        chat={isChatUninitialized ? undefined : chat}
        errorProps={{
          errorMessageId,
          errorMessage,
          clearErrorAndStartNewChat
        }}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleMessageSumbit(message);
        }}
      >
        <MessageEditor
          fullWidth
          autoComplete="off"
          disabled={websocketOpen || chatError}
          inputRef={inputRef}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit">
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={t("assistant.typeMessage")}
          value={message}
          variant="outlined"
        />
      </form>
    </Box>
  );
};
