import useAuthContext from "@core/auth/AuthContext";
import { useAuthorization } from "@core/auth/authz/useAuthorization";
import { Page } from "@core/layout/page";
import YourOrgBadge from "@features/organization/YourOrgBadge";
import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteOrganizationMutation, useGetOrganizationsQuery } from "../../app/services/appApi";
import { withAuth } from "../../core/auth/authWrappers";
import { SimpleSearchBar } from "../../core/input";
import OrganizationFormDialog from "./organizationFormDialog";

const OrganizationListPage = () => {
  const { t } = useTranslation();
  const { can } = useAuthorization();
  const authContext = useAuthContext();

  const [searchValue, setSearchValue] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });
  const [createOrganizationDialogOpen, setCreateOrganizationDialogOpen] = useState(false);
  const [editOrganizationId, setEditOrganizationId] = useState<string | undefined>(undefined);

  const { data, isLoading } = useGetOrganizationsQuery({
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
    search: searchValue,
  });
  const [triggerDelete, { isError, isSuccess, reset: resetDelete }] = useDeleteOrganizationMutation();

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("notifications.modelDelete.success", { model: t("models.organization") }), {
        variant: "success",
      });
      resetDelete();
    }
    if (isError) {
      enqueueSnackbar(t("notifications.modelDelete.failure", { model: t("models.organization") }), {
        variant: "error",
      });
    }
  }, [isSuccess, isError, t, resetDelete]);

  const columns = useMemo(
    (): (GridColDef & { isVisible?: boolean })[] => [
      {
        field: "id",
        headerName: t("organizations.headings.id"),
        minWidth: 80,
      },
      {
        field: "name",
        headerName: t("organizations.headings.name"),
        flex: 1,
        minWidth: 200,
        renderCell: (params) => (
          <>
            {params.row.name}
            {params.row?.id === authContext.user?.organization.id && <YourOrgBadge sx={{ ml: 1 }} />}
          </>
        ),
      },
      {
        field: "max_child_orgs",
        headerName: t("organizations.headings.maxChildOrgs"),
        minWidth: 150,
        isVisible: can("ORGANIZATION.MAX_CHILD_ORG", "WRITE"),
      },
      {
        field: "max_users",
        headerName: t("organizations.headings.userUsage"),
        minWidth: 150,
        renderCell: (params) => {
          // Current / max
          return `${params.row.curr_reserved_slots} / ${params.row.max_users}`;
        },
      },
      {
        field: "actions",
        type: "actions",
        minWidth: 80,
        getActions: (params) =>
          params.row.id !== authContext.user?.organization.id
            ? [
                <GridActionsCellItem
                  closeMenuOnClick
                  showInMenu
                  label={t("organizations.edit")}
                  icon={<EditOutlined />}
                  onClick={() => setEditOrganizationId(params.row.id)}
                />,
                <GridActionsCellItem
                  closeMenuOnClick
                  showInMenu
                  label={t("organizations.delete")}
                  icon={<DeleteOutlineOutlined />}
                  onClick={() => triggerDelete(params.row.id)}
                />,
              ]
            : [],
      },
    ],
    [t, triggerDelete],
  );

  const actions = (
    <>
      <SimpleSearchBar onSearch={setSearchValue} size="small" />
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        color="primary"
        onClick={() => setCreateOrganizationDialogOpen(true)}
      >
        {t("organizations.add")}
      </Button>
    </>
  );

  return (
    <>
      <Page title={t("organizations.title")} actions={actions}>
        <DataGrid
          disableRowSelectionOnClick
          rows={data?.results || []}
          columns={columns.filter((column) => column.isVisible !== false)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[20, 50, 100]}
          sx={{ flex: 1, "& .MuiDataGrid-footerContainer": { flexDirection: "row-reverse" } }}
          loading={isLoading}
          paginationMode="server"
          rowCount={data?.count || 0}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </Page>
      <OrganizationFormDialog
        open={createOrganizationDialogOpen}
        onClose={() => setCreateOrganizationDialogOpen(false)}
      />
      <OrganizationFormDialog
        open={!!editOrganizationId}
        onClose={() => setEditOrganizationId(undefined)}
        organizationId={editOrganizationId}
      />
    </>
  );
};

export default withAuth(OrganizationListPage);
