import { IChatSemanticSearchToolReturn } from "@app/services/apiTypes";
import { INoteRead } from "@features/note/noteTypes";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

interface ResourceViewerHeaderProps {
  note?: INoteRead;
  onClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  resource?: IChatSemanticSearchToolReturn;
}

export const ResourceViewerHeader = ({ note, onClose, resource }: ResourceViewerHeaderProps) => (
  <Stack direction="row" alignItems="center" justifyContent="space-between" pl={2} pr={1}>
    {(note || resource) && <Typography variant="body2">{note?.title ?? resource?.title}</Typography>}
    <IconButton onClick={onClose}>
      <CloseIcon />
    </IconButton>
  </Stack>
);

export default ResourceViewerHeader;
