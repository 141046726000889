import { Page } from "@core/layout/page";
import TranslateIcon from "@mui/icons-material/Translate";
import PsychologyIcon from '@mui/icons-material/Psychology';
import { 
  Box, 
  Container, 
  Stack, 
  Typography, 
  TextField,
  Card,
  Button,
  CircularProgress
} from "@mui/material";
import { styled } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { withAuth } from "../../core/auth/authWrappers";
import LanguageSelect from "./LanguageSelect";

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '650px',
  },
}));

const ProfilePage: React.FC = () => {
  const { t } = useTranslation();
  const [promptContext, setPromptContext] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [charCount, setCharCount] = useState(0);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      // Add your save logic here
      await new Promise(resolve => setTimeout(resolve, 1000)); // Simulate API call
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Page title={t("settings.title")}>
      <Container 
        maxWidth={false} 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center',
          py: 4
        }}
      >
        <Stack spacing={3} sx={{ width: '100%', alignItems: 'center' }}>
          <StyledCard>
            <Box display="flex" alignItems="center">
              <TranslateIcon sx={{ mr: 2, color: 'primary.main' }} />
              <Typography variant="h5" component="h2">
                {t("settings.language.title")}
              </Typography>
            </Box>
            <Typography 
              variant="body1" 
              color="text.secondary"
            >
              {t("settings.language.message")}
            </Typography>
            <LanguageSelect />
          </StyledCard>

          <StyledCard>
            <Box display="flex" alignItems="center">
              <PsychologyIcon sx={{ mr: 2, color: 'primary.main' }} />
              <Typography variant="h5" component="h2">
                {t("settings.prompt_context.title")}
              </Typography>
            </Box>
            <Typography 
              variant="body1" 
              color="text.secondary"
            >
              {t("settings.prompt_context.message")}
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              value={promptContext}
              onChange={(e) => {
                setPromptContext(e.target.value);
                setCharCount(e.target.value.length);
              }}
              placeholder={t("settings.prompt_context.placeholder")}
              helperText={`${charCount}/500 characters`}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'background.paper',
                  borderRadius: '8px',
                }
              }}
            />
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                startIcon={isSaving ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
                onClick={handleSave}
                disabled={isSaving}
                sx={{
                  borderRadius: '8px',
                  textTransform: 'none',
                  px: 3,
                }}
              >
                {isSaving ? t("common.saving") : t("common.save")}
              </Button>
            </Box>
          </StyledCard>
        </Stack>
      </Container>
    </Page>
  );
};

export default withAuth(ProfilePage);