import { useChangePasswordUserMutation } from "@app/services/appApi";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import BusinessIcon from '@mui/icons-material/Business';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import TagIcon from '@mui/icons-material/Tag';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Button from "@mui/material/Button";
import { Avatar, TextField, Divider, Container } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useAuthContext from "../../core/auth/AuthContext";
import { withAuth } from "../../core/auth/authWrappers";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, ref, string } from "yup";
import { enqueueSnackbar } from "notistack";
import { TFunction } from "i18next";
import { Page } from "@core/layout/page";

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '900px',
  },
}));

const ProfileItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderRadius: '10px',
  backgroundColor: theme.palette.background.default,
  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1)',
  border: '1px solid',
  borderColor: theme.palette.divider,
}));

interface FormValues {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

const DEFAULT_VALUES = {
  oldPassword: "",
  newPassword: "",
  newPasswordConfirmation: "",
};

const schema = (t: TFunction) =>
  object({
    oldPassword: string()
      .required(t("validation.requiredField"))
      .min(3, t("validation.minLength", { count: 3 })),
    newPassword: string()
      .required(t("validation.requiredField"))
      .min(3, t("validation.minLength", { count: 3 })),
    newPasswordConfirmation: string()
      .required(t("validation.requiredField"))
      .min(3, t("validation.minLength", { count: 3 }))
      .oneOf([ref("newPassword")], t("profile.newPasswordConfirmation.passwordsMustMatch")),
  }).required(t("validation.requiredField"));

const Profile: FC = () => {
  const { t } = useTranslation();
  const { user, logout } = useAuthContext();

  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm({
    resolver: yupResolver(schema(t)),
    defaultValues: DEFAULT_VALUES,
  });

  const [
    changePasswordUser,
    { isError: isErrorChangePassword, isSuccess: isSuccessChangePassword, reset: resetPasswordChange },
  ] = useChangePasswordUserMutation();

  useEffect(() => {
    if (isSuccessChangePassword) {
      enqueueSnackbar(t("profile.changePassword.success"), { variant: "success" });
      resetPasswordChange();
      resetForm();
    }
  }, [isSuccessChangePassword, resetForm, resetPasswordChange, t]);

  useEffect(() => {
    if (isErrorChangePassword) {
      enqueueSnackbar(t("profile.changePassword.failure"), { variant: "error" });
      resetPasswordChange();
    }
  }, [isErrorChangePassword, resetPasswordChange, t]);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString("it-IT", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  };

  const changePassword = useCallback(
    (data: FormValues) => {
      changePasswordUser({
        old_password: data.oldPassword,
        new_password: data.newPassword,
        confirm_password: data.newPasswordConfirmation,
      });
    },
    [changePasswordUser],
  );

  return (
    <Page title={t("profile.title")}>
      <Container
        maxWidth={false}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          py: 4
        }}
      >
        <Stack spacing={3} sx={{ width: '100%', alignItems: 'center' }}>

          <StyledCard>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 4 }}>
              <Avatar sx={{ bgcolor: 'primary.main', width: 80, height: 80 }}>
                <AccountCircleOutlinedIcon sx={{ fontSize: 50 }} />
              </Avatar>
              <Typography variant="h6" sx={{ mt: 2, fontWeight: "bold" }}>
                {user?.username}
              </Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <ProfileItem>
                  <Box>
                    <Typography color="text.secondary" variant="subtitle2" sx={{ mb: 1 }}>
                      {t("profile.fields.organization")}
                    </Typography>
                    <Typography variant="body1" fontWeight="medium">
                      {user?.organization.name}
                    </Typography>
                  </Box>
                  <BusinessIcon sx={{ color: 'primary.light' }} />
                </ProfileItem>
              </Grid>

              <Grid item xs={12} sm={6}>
                <ProfileItem>
                  <Box>
                    <Typography color="text.secondary" variant="subtitle2" sx={{ mb: 1 }}>
                      {t("profile.fields.organizationId")}
                    </Typography>
                    <Typography variant="body1" fontWeight="medium">
                      {user?.organization.id}
                    </Typography>
                  </Box>
                  <TagIcon sx={{ color: 'primary.light' }} />
                </ProfileItem>
              </Grid>

              <Grid item xs={12} sm={6}>
                <ProfileItem>
                  <Box>
                    <Typography color="text.secondary" variant="subtitle2" sx={{ mb: 1 }}>
                      {t("profile.fields.role")}
                    </Typography>
                    <Typography variant="body1" fontWeight="medium">
                      {user?.role}
                    </Typography>
                  </Box>
                  <SupervisorAccountIcon sx={{ color: 'primary.light' }} />
                </ProfileItem>
              </Grid>

              <Grid item xs={12} sm={6}>
                <ProfileItem>
                  <Box>
                    <Typography color="text.secondary" variant="subtitle2" sx={{ mb: 1 }}>
                      {t("profile.fields.createdAt")}
                    </Typography>
                    <Typography variant="body1" fontWeight="medium">
                      {user?.created_at && formatDate(user.created_at)}
                    </Typography>
                  </Box>
                  <CalendarTodayIcon sx={{ color: 'primary.light' }} />
                </ProfileItem>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="error"
              onClick={logout}
              sx={{
                borderRadius: '10px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                py: 1,
              }}
            >
              {t("profile.logout")}
            </Button>

          </StyledCard>
          <StyledCard>


            <Box
              component="form"
              onSubmit={handleSubmit(changePassword)}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {t("profile.changePassword.title")}
              </Typography>
              <Controller
                name="oldPassword"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="password"
                    label={t("profile.oldPassword.label")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name="newPassword"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="password"
                    label={t("profile.newPassword.label")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name="newPasswordConfirmation"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="password"
                    label={t("profile.newPasswordConfirmation.label")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  borderRadius: '10px',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                  py: 1,
                }}
              >
                {t("profile.changePassword.title")}
              </LoadingButton>
            </Box>

          </StyledCard>
        </Stack>
      </Container>
    </Page>
  );
};

export default withAuth(Profile);