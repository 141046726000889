import { useDeleteDeviceMutation, useGetDevicesQuery } from "@app/services/appApi";
import useAuthContext from "@core/auth/AuthContext";
import { withAuth } from "@core/auth/authWrappers";
import { useAuthorization } from "@core/auth/authz/useAuthorization";
import { SimpleSearchBar } from "@core/input";
import { Page } from "@core/layout/page";
import YourOrgBadge from "@features/organization/YourOrgBadge";
import TagsFilterDialog from "@features/tags/TagsFilterDialog";
import { TagsList } from "@features/tags/TagsList";
import { DeleteOutlineOutlined, EditOutlined, FilterAlt } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Badge, Button } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DeviceFormDialog from "./DeviceFormDialog";

const DeviceListPage = () => {
  const { can } = useAuthorization();
  const { t } = useTranslation();
  const authContext = useAuthContext();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });
  const [searchValue, setSearchValue] = useState("");
  const [filteredTags, setFilteredTags] = useState<string[] | undefined>(undefined);
  const [isFiltersDialogOpen, setFiltersDialogOpen] = useState(false);
  const [createDeviceDialogOpen, setCreateDeviceDialogOpen] = useState(false);
  const [editDeviceId, setEditDeviceId] = useState<string | undefined>(undefined);

  const { data, isLoading } = useGetDevicesQuery({
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
    search: searchValue,
    tagIds: filteredTags,
  });
  const [triggerDelete, { isError, isSuccess, reset: resetDelete }] = useDeleteDeviceMutation();

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("notifications.modelDelete.success", { model: t("models.device") }), { variant: "success" });
      resetDelete();
    }
    if (isError) {
      enqueueSnackbar(t("notifications.modelDelete.failure", { model: t("models.device") }), { variant: "error" });
    }
  }, [isSuccess, isError, t, resetDelete]);

  const columns = useMemo(
    (): (GridColDef & { isVisible?: boolean })[] => [
      {
        field: "id",
        headerName: t("devices.headings.id"),
        minWidth: 80,
        isVisible: can("READ", "id_device")
      },
      {
        field: "name",
        headerName: t("devices.headings.name"),
        flex: 1,
        minWidth: 200,
      },
      {
        field: "organization",
        headerName: t("users.headings.organization"),
        flex: 1,
        minWidth: 140,
        renderCell: (params) => (
          <>
            {params.row.organization?.name}
            {params.row.organization?.id === authContext.user?.organization.id && <YourOrgBadge sx={{ ml: 1 }} />}
          </>
        ),
      },
      {
        field: "tags",
        headerName: t("devices.headings.tags"),
        flex: 1,
        minWidth: 140,
        renderCell: (params) => <TagsList tags={params.row.tags} />,
        valueGetter: (params) => params.row.tags.map((tag: any) => tag.name).join(", "),
      },
      {
        field: "actions",
        type: "actions",
        minWidth: 80,
        isVisible: can("UPDATE", "DEVICE") && can("DELETE", "DEVICE"),
        getActions: (params) => [
          <GridActionsCellItem
            closeMenuOnClick
            showInMenu
            label={t("devices.edit")}
            icon={<EditOutlined />}
            onClick={() => setEditDeviceId(params.row.id)}
          />,
          <GridActionsCellItem
            closeMenuOnClick
            showInMenu
            label={t("devices.delete")}
            icon={<DeleteOutlineOutlined />}
            onClick={() => triggerDelete(params.row.id)}
          />,
        ],
      },
    ],
    [t, triggerDelete, can],
  );

  const actions = (
    <>
      <SimpleSearchBar onSearch={setSearchValue} size="small" />
      <Badge badgeContent={filteredTags?.length} color="primary">
        <Button startIcon={<FilterAlt />} onClick={() => setFiltersDialogOpen(true)}>
          {t("fileManager.filters")}
        </Button>
      </Badge>
      {can("CREATE", "DEVICE") && (
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          color="primary"
          onClick={() => setCreateDeviceDialogOpen(true)}
        >
          {t("devices.add")}
        </Button>
      )}
    </>
  );

  return (
    <>
      <Page title={t("devices.title")} actions={actions}>
        <DataGrid
          disableRowSelectionOnClick
          rows={data?.results ?? []}
          columns={columns.filter((column) => column.isVisible !== false)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[20, 50, 100]}
          sx={{ flex: 1, "& .MuiDataGrid-footerContainer": { flexDirection: "row-reverse" } }}
          loading={isLoading}
          paginationMode="server"
          rowCount={data?.count ?? 0}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </Page>
      <TagsFilterDialog
        applyFilters={setFilteredTags}
        filteredTags={filteredTags}
        open={isFiltersDialogOpen}
        onClose={() => setFiltersDialogOpen(false)}
      />
      <DeviceFormDialog open={createDeviceDialogOpen} onClose={() => setCreateDeviceDialogOpen(false)} />
      <DeviceFormDialog open={!!editDeviceId} onClose={() => setEditDeviceId(undefined)} deviceId={editDeviceId} />
    </>
  );
};

export default withAuth(DeviceListPage);
