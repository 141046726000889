import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Stack, styled, TableCell, TableRow, Box } from "@mui/material";
import React, { memo } from "react";
import { useMultiDrop } from "react-dnd-multi-backend";
import { IStorageFileRead } from "./storageFileTypes";
import {
  DraggableItemTypes,
  getNewDirectoryPathInParentDirectory,
  getNewFilePathInParentDirectory,
  isDirectory,
} from "./utils";

interface StyledRowProps {
  isOver: boolean;
  canDrop: boolean;
}

const StyledRow = styled(TableRow)<StyledRowProps>(({ isOver, canDrop }) => ({
  cursor: isOver && !canDrop ? "no-drop" : "default",
  ":active": {
    cursor: "grabbing",
  },
  ...(isOver && {
    backgroundColor: "rgba(25, 118, 210, 0.1)",
  }),
}));

interface ParentDirectoryItemProps {
  currentPath: string;
  findFile: (id: string) => { file: IStorageFileRead; index: number };
  moveFile: (file: IStorageFileRead, newPath: string) => Promise<void>;
  setCurrentPath: (path: string) => void;
}

interface Item {
  id: string;
  originalIndex: number;
}

const ParentDirectoryItem: React.FC<ParentDirectoryItemProps> = memo(
  ({ currentPath, findFile, moveFile, setCurrentPath }) => {
    const [[{ isOver, canDrop }, drop]] = useMultiDrop({
      accept: [DraggableItemTypes.File, DraggableItemTypes.Directory],
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      drop: ({ id: draggedId }: Item) => {
        const { file } = findFile(draggedId);

        if (isDirectory(file.user_fullpath)) {
          moveFile(file, getNewDirectoryPathInParentDirectory(file));
        } else {
          moveFile(file, getNewFilePathInParentDirectory(file));
        }
      },
    });

    return (
      <StyledRow hover canDrop={canDrop} isOver={isOver} ref={(elem) => drop(elem)}>
        <TableCell colSpan={7}>
          <Stack
            alignItems="center"
            direction="row"
            flexWrap="nowrap"
            gap={1}
            sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
          >
            <Box
              sx={{
                width: 32,
                height: 32,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                backgroundColor: "transparent",
                cursor: "pointer",
                hover: {
                  backgroundColor: "rgba(185,192,200)",
                },
              }}
              onClick={() => setCurrentPath(currentPath.split("/").slice(0, -1).join("/"))}
            >
              <ArrowBackIcon sx={{ color: "#1876D1" }} />
            </Box>
          </Stack>
        </TableCell>
      </StyledRow>
    );
  },
);

export default ParentDirectoryItem;
