import { useCreateNoteMutation, useGetNoteQuery, useUpdateNoteMutation } from "@app/services/appApi";
import TagSelect from "@features/tags/TagSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { stopPropagate } from "@utils/form";
import { TFunction } from "i18next";
import { enqueueSnackbar } from "notistack";
import React, { ComponentProps, useCallback, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { array, object, string } from "yup";
import Quill from "./Quill";
import { INoteCreate, INoteRead, INoteUpdate } from "./noteTypes";

const DEFAULT_CREATE_VALUES: INoteCreate = {
  title: "",
  body: "",
  body_html: "",
  tag_ids: [],
};

const cleanReadDataForUpdate = (note: INoteRead): INoteUpdate => {
  const { title, body, body_html, tags } = note;
  return { tag_ids: tags.map((tag) => tag.id), title, body, body_html };
};

interface INoteFormDialogProps extends ComponentProps<typeof Dialog> {
  noteId?: INoteRead["id"];
}

const schema = (t: TFunction) =>
  object({
    title: string().required(t("validation.requiredField")),
    body: string().required(t("validation.requiredField")),
    body_html: string().required(t("validation.requiredField")),
    tag_ids: array()
      .of(string().required(t("validation.requiredField")))
      .required(t("validation.requiredField")),
  }).required(t("validation.requiredField"));

const NoteFormDialog: React.FC<INoteFormDialogProps> = ({ noteId, ...props }) => {
  const createMode = !noteId;

  const { t } = useTranslation();

  const form = useForm<INoteCreate | INoteUpdate>({ resolver: yupResolver(schema(t)) });
  const { control, handleSubmit, reset } = form;

  // Delay data fetching until noteId is defined and dialog is opened
  const { data: note, isLoading: isDataLoading } = useGetNoteQuery(noteId ?? skipToken, { skip: !props.open });
  const [
    triggerCreate,
    { isLoading: isCreateLoading, isSuccess: isCreateSuccess, isError: isCreateError, reset: resetCreate },
  ] = useCreateNoteMutation();
  const [
    triggerUpdate,
    { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate },
  ] = useUpdateNoteMutation();

  const isLoading = isCreateLoading || isUpdateLoading || isDataLoading;

  useEffect(() => {
    if (note) {
      reset(cleanReadDataForUpdate(note));
    } else {
      reset(DEFAULT_CREATE_VALUES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note, props.open]);

  const sendData = (data: INoteCreate | INoteUpdate) => {
    if (createMode) {
      triggerCreate(data);
    } else {
      triggerUpdate({
        id: noteId || "",
        ...data,
      });
    }
  };

  const handleClose = useCallback(
    (event: {}, reason: "escapeKeyDown" | "backdropClick") => {
      if (reason === "backdropClick") return; // Ignora il click fuori dal dialog
      props.onClose?.(event, reason);
    },
    [props],
  );

  // Handle error and success notifications
  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar(t("notifications.modelCreate.failure", { model: t("models.note") }), { variant: "error" });
    }
    if (isCreateSuccess) {
      enqueueSnackbar(t("notifications.modelCreate.success", { model: t("models.note") }), { variant: "success" });
      handleClose({}, "escapeKeyDown");
      resetCreate();
    }
    if (isUpdateError) {
      enqueueSnackbar(t("notifications.modelUpdate.failure", { model: t("models.note") }), { variant: "error" });
    }
    if (isUpdateSuccess) {
      enqueueSnackbar(t("notifications.modelUpdate.success", { model: t("models.note") }), { variant: "success" });
      handleClose({}, "escapeKeyDown");
      resetUpdate();
    }
  }, [handleClose, isCreateError, isCreateSuccess, isUpdateError, isUpdateSuccess, reset, resetCreate, resetUpdate, t]);

  return (
    <Dialog fullWidth maxWidth="sm" {...props} onClose={handleClose}>
      <DialogTitle>{createMode ? t("notes.formDialog.createTitle") : t("notes.formDialog.editTitle")}</DialogTitle>
      {!isDataLoading ? (
        <form onSubmit={stopPropagate(handleSubmit(sendData))}>
          <DialogContent>
            <Stack gap={2}>
              <Controller
                name={"title"}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    autoFocus
                    fullWidth
                    required
                    label={t("notes.formDialog.title.label")}
                    type="text"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
              <Controller name={"tag_ids"} control={control} render={({ field }) => <TagSelect {...field} />} />
              <FormProvider {...form}>
                <Box>
                  <Box
                    sx={{
                      mb: 1,
                      typography: "body1",
                      color:
                        form.formState.errors.body_html || form.formState.errors.body ? "error.main" : "text.primary",
                      "&::after": {
                        content: '" *"',
                      },
                    }}
                  >
                    {t("notes.formDialog.content.label")}
                  </Box>
                  <Quill htmlFieldName="body_html" textFieldName="body" />
                  {(form.formState.errors.body_html || form.formState.errors.body) && (
                    <Box
                      sx={{
                        mt: 0.25, // Reduced from 0.5 to 0.25 to minimize space
                        ml: 1.5,
                        typography: "caption",
                        color: "error.main",
                        fontSize: "0.75rem",
                        lineHeight: 1.66,
                        letterSpacing: "0.03333em",
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                      }}
                    >
                      {t("validation.requiredField")}
                    </Box>
                  )}
                </Box>
              </FormProvider>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ m: 2 }}>
            <Button startIcon={<CloseOutlinedIcon />} onClick={(event) => handleClose(event, "escapeKeyDown")}>
              {t("notes.formDialog.cancel")}
            </Button>
            <LoadingButton
              startIcon={<CheckOutlinedIcon />}
              loading={isLoading}
              disabled={isLoading}
              variant="contained"
              type="submit"
            >
              {createMode ? t("notes.formDialog.create") : t("notes.formDialog.update")}
            </LoadingButton>
          </DialogActions>
        </form>
      ) : (
        <DialogContent>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default NoteFormDialog;
