import DeviceSelect from "@features/device/DeviceSelect";
import { Close, Refresh } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IChat, IChatTextMessage } from "../../../app/services/apiTypes";
import { BotAvatar } from "./ChatAvatars";
import { CHAT_BACKGROUND_COLOR, CHAT_BORDER_COLOR } from "./utils";


import theme from "../../../theme";


interface ChatColumnHeaderProps {
  chat?: IChat;
  isChatUninitialized?: boolean;
  onClose: () => void;
  resetChat: () => void;
}

export default function ChatColumnHeader({
  chat,
  isChatUninitialized = false,
  onClose,
  resetChat,
}: ChatColumnHeaderProps) {
  const { t } = useTranslation();

  const assistant = theme.app.assistant;

  return (
    <Stack
      alignItems="center"
      sx={{
        backgroundColor: CHAT_BACKGROUND_COLOR,
        borderBottom: isChatUninitialized ? 0 : 1,
        borderBottomColor: CHAT_BORDER_COLOR,
        zIndex: 1,
        px: {
          xs: 2,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 4,
        },
        py: 3,
      }}
    >
      {(isChatUninitialized || !chat?.messages.length) && <BotAvatar large />}
      <Typography variant="h6" className="FontSpecial" align="center" >
        {assistant.name}
      </Typography>
      {isChatUninitialized || !chat?.messages.length ? (
        <>
          {/* <Typography variant="body1" align="center">
            {t("assistant.description")}
          </Typography>*/
            <DeviceSelect />}
        </>
      ) : (
        <Typography variant="body2" align="center">
          {t("assistant.device")}:{" "}
          {(chat.messages[0] as IChatTextMessage).context_device?.name ?? t("assistant.unknownDevice")}
        </Typography>
      )}
      {!isChatUninitialized && !!chat?.messages.length && (
        <Tooltip title={t("assistant.newChat")} placement="right">
          <IconButton
            onClick={resetChat}
            sx={{
              position: "absolute",
              top: 16,
              left: {
                xs: 16,
                sm: 16,
                md: 32,
                lg: 32,
                xl: 32,
              },
            }}
          >
            <Refresh />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={t("assistant.close")} placement="left">
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 16,
            right: {
              xs: 16,
              sm: 16,
              md: 32,
              lg: 32,
              xl: 32,
            },
          }}
        >
          <Close />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}
