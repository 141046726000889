import { createTheme, responsiveFontSizes, ThemeOptions, typographyClasses } from "@mui/material";
import { grey } from "@mui/material/colors";
import { merge } from "lodash";
import "./stylesheet.css";

// https://mui.com/customization/default-theme/
// https://zenoo.github.io/mui-theme-creator


// use module augmentation to add custom variables to the Theme
declare module "@mui/material/styles" {
  interface Theme {
    app: {
      /** Name shown in the browser page title */
      title: string;
      /** Name shown in the nav bar */
      name: string;
      /** Displayed under the name. leave empty to disable */
      caption: string;
      /** Leave empty to disable the link */
      contactUrl: string;
      /** Definitions for app logo in several components */
      logo: {
        square: {
          backgroundColor: string;
          borderRadius: number;
          imgSrc: string;
        };
        favicon: {
          icon96: string;
          iconSvg: string;
          iconIco: string;
          appleTouchIcon: string;
          webManifest: string;
        };
      };
      poweredBy: {
        showInNav: boolean;
        showInLoading: boolean;
        /** Leave empty to disable the link */
        url: string;
      };
      nav: {
        backgroundColor: string;
        textColor: string;
      };
      typography?: {
        fontFamily?: string;
      };
      assistant: {
        name: string;
        logo: {
          src: string;
        };
      };
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions extends Partial<Theme> { }
}

const defaultThemeConfigs: ThemeOptions = {
  palette: {
    // text: {
    //     primary: grey["500"],
    //     // secondary: grey[700],
    // },
    // primary: {
    //     main: "#624E90",
    //     contrastText: grey[50],
    // },
    // secondary: {
    //     main: "#5C2DC8",
    // },
    // background: {
    //     default: "#fafafa",
    // },
  },
  app: {
    title: "Mira",
    name: "MIRA",
    caption: "by Mastranet AI ",
    contactUrl: "https://mastranet.ai/contact-us",
    logo: {
      square: {
        backgroundColor: grey[100],
        borderRadius: 10,
        imgSrc: "/static/logo/mnet_square.svg",
      },
      favicon: {
        icon96: "/favicon/favicon-96x96.png",
        iconSvg: "/favicon/favicon.svg",
        iconIco: "/favicon/favicon.ico",
        appleTouchIcon: "/favicon/apple-touch-icon.png",
        webManifest: "/favicon/site.webmanifest",
      },
    },
    poweredBy: {
      showInNav: true,
      showInLoading: true,
      url: "https://mastranet.ai?utm_source=MIRA",
    },
    nav: {
      textColor: "#e1e1e1",
      backgroundColor: "#121827",
    },
    assistant: {
      name: "Mira Assistant",
      logo: {
        src: "/static/logo/mira_logo.svg",
      },
    },
  },
};
const defaultTheme = createTheme(defaultThemeConfigs);

const willieMateThemeConfig: ThemeOptions = merge(defaultThemeConfigs, {
  palette: {
    primary: {
      main: "#624E90",
      contrastText: grey[50],
    },
    secondary: {
      main: "#5C2DC8",
    },
  },
  typography: {
    //set the new font only for h5 with the classname FontSpecial
    h6: {
      "&.FontSpecial": {
        fontFamily: "Episodian-Bold",
        // fontSize: "1.3rem",
      },
    },
  },
  app: {
    title: "willie mate",
    name: "willie mate",
    caption: "",
    contactUrl: "https://jay2you.com/en/#contact",
    logo: {
      square: {
        imgSrc: "/static/logo/logo_williemate_viola.png",
        width: 32,
        height: 32,
      },
      favicon: {
        icon96: "/favicon/williemate/favicon-96x96.png",
        iconSvg: "/favicon/williemate/favicon.svg",
        iconIco: "/favicon/williemate/favicon.ico",
        appleTouchIcon: "/favicon/williemate/apple-touch-icon.png",
        webManifest: "/favicon/williemate/site.webmanifest",
      },
    },
    poweredBy: {
      url: "https://mastranet.ai?utm_source=Willie Mate",
    },
    nav: {
      textColor: "#e1e1e1",
      backgroundColor: "#443664",
    },
    assistant: {
      name: "willie mate",
      logo: {
        src: "/static/logo/logo_williemate_viola.png",
      },
    },
  },
});
const willieMateTheme = createTheme(willieMateThemeConfig);

const ddxThemeConfig: ThemeOptions = merge(defaultThemeConfigs, {
  palette: {
    primary: {
      main: "#5aa340",
      contrastText: grey[50],
    },
    secondary: {
      main: "#5C2DC8",
    },
  },
  typography: {
    fontFamily: "Episodian-Bold",
  },
  app: {
    title: "DDX Assistant",
    name: "DDX Assistant",
    caption: "by DDX Group",
    contactUrl: "https://www.ddxgroup.com/",
    logo: {
      square: {
        imgSrc: "/static/logo/ddxlogo.png",
      },
    },
    poweredBy: {
      url: "https://mastranet.ai?utm_source=DDX Assistant",
    },
    nav: {
      textColor: "#e1e1e1",
      backgroundColor: "#443664",
    },
    assistant: {
      logo: {
        src: "/static/logo/ddxlogo.png",
      },
    },
  },
});
const ddxTheme = createTheme(ddxThemeConfig);

const robotecoThemeConfig: ThemeOptions = merge(defaultThemeConfigs, {
  palette: {
    primary: {
      main: "#5aa340",
      contrastText: grey[50],
    },
    secondary: {
      main: "#5aa340",
    },
  },
  app: {
    title: "Roboteco Assistance",
    name: "Assistance",
    caption: "by Roboteco-Italargon",
    contactUrl: "https://www.roboteco-italargon.it/contatti/",
    logo: {
      square: {
        imgSrc: "/static/logo/logo_roboteco_rotondo.png",
      },
      favicon: {
        icon96: "/favicon/roboteco/favicon-96x96.png",
        iconSvg: "/favicon/roboteco/favicon.svg",
        iconIco: "/favicon/roboteco/favicon.ico",
        appleTouchIcon: "/favicon/roboteco/apple-touch-icon.png",
        webManifest: "/favicon/roboteco/site.webmanifest",
      },
    },
    poweredBy: {
      url: "https://mastranet.ai?utm_source=Roboteco Assistant",
    },
    nav: {
      textColor: "#e1e1e0",
      backgroundColor: "#113902",
    },
    assistant: {
      name: "Roboteco Assistant",
      logo: {
        src: "/static/logo/logo_roboteco_rotondo.png",
      },
    },
  },
});
const robotecoTheme = createTheme(robotecoThemeConfig);

const getTheme = () => {
  if (/.*jay2you\.(com|local)$/.test(window.location.hostname)) {
    // Domain ends with jay2you.com, use Willie Mate theme
    return willieMateTheme;
  } else if (/.*ddx\.mastranet\.(ai|local)$/.test(window.location.hostname)) {
    // Domain ends in *.ddx.mastranet.ai, use DDX theme
    return ddxTheme;
  } else if (/.*roboteco\.(com|local)$/.test(window.location.hostname)) {
    // Domain ends with roboteco.com, use Roboteco theme
    return robotecoTheme;
  }
  // Default theme
  return defaultTheme;
};

export default responsiveFontSizes(getTheme());
