import { Box, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import BusinessIcon from '@mui/icons-material/Business';
import Avatar from '@mui/material/Avatar';


interface YourOrgBadgeProps extends React.ComponentProps<typeof Box> {
}

const YourOrgBadge: React.FC<YourOrgBadgeProps> = (props) => {

    const { t } = useTranslation();

    return (
        <Box {...props}>
            <Tooltip title={t("organizations.yourOrg")} arrow>
                <Avatar sx={{ width: 24, height: 24, bgcolor: 'primary.main' }} >
                    <BusinessIcon sx={{ fontSize: 14 }} />
                </Avatar>
            </Tooltip>
        </Box>
    )
}


export default YourOrgBadge;