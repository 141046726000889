import { useAuthorization } from "@core/auth/authz/useAuthorization";
import { TagsList } from "@features/tags/TagsList";
import ResourceViewerModal from "@features/viewer/ResourceViewerModal";
import { Delete as DeleteIcon, Download as DownloadIcon, InsertDriveFileOutlined } from "@mui/icons-material";
import HelpIcon from "@mui/icons-material/Help";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  styled,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { memo, useCallback, useMemo, useState } from "react";
import { useMultiDrag, usePreview } from "react-dnd-multi-backend";
import { useTranslation } from "react-i18next";
import EditFileFormDialog from "./EditFileFormDialog";
import { IStorageFileRead } from "./storageFileTypes";
import { DraggableItemTypes, formatFileSize, getItemName } from "./utils";
import { Close as CloseIcon } from '@mui/icons-material';

interface StyledRowProps {
  isDragging: boolean;
  isLoading: boolean;
  canDrag: boolean;
}

const StyledRow = styled(TableRow)<StyledRowProps>(({ isDragging, isLoading, canDrag }) => ({
  backgroundColor: isLoading ? "rgba(0, 0, 0, 0.04)" : "inherit",
  cursor: canDrag ? (isDragging ? "grab" : "grabbing") : "default",
  opacity: isDragging ? 0.5 : 1,
  ":active": {
    cursor: canDrag ? "grabbing" : "default",
  },
  "&:hover .icon-container": {
    visibility: "visible",
  },
}));

const StyledCell = styled(TableCell)(() => ({
  maxWidth: 240,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

const IconContainer = styled("div")({
  visibility: "hidden",
  display: "flex",
  alignItems: "center",
});

interface FileItemProps {
  canDrag?: boolean;
  deleteFile: (file: IStorageFileRead) => void;
  downloadFile: (file: IStorageFileRead) => void;
  file: IStorageFileRead;
  findFile: (id: string) => { file: IStorageFileRead; index: number };
  isLoading?: boolean;
  onFileOpen: (isOpen: boolean) => void;
}

const FileItem: React.FC<FileItemProps> = memo(
  ({
    canDrag = false,
    deleteFile: deleteFileProps,
    downloadFile: downloadFileProps,
    file,
    findFile,
    isLoading = false,
    onFileOpen,
  }) => {
    const { can } = useAuthorization();
    const { t } = useTranslation();
    const preview = usePreview();

    const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);

    const handleModalOpen = useCallback(() => {
      setIsPDFModalOpen(true);
      onFileOpen(true);
    }, [onFileOpen]);

    const handleModalClose = useCallback(() => {
      setIsPDFModalOpen(false);
      onFileOpen(false);
    }, [onFileOpen]);

    const openFile = useCallback(
      (event: React.MouseEvent<HTMLTableRowElement>) => {
        if (file.extension.toLowerCase() === ".pdf") {
          handleModalOpen();
        }
      },
      [file, handleModalOpen],
    );

    const deleteFile = useCallback(() => deleteFileProps(file), [deleteFileProps, file]);
    const downloadFile = useCallback(() => downloadFileProps(file), [downloadFileProps, file]);

    const statusColor = useMemo(() => {
      switch (file.index_status) {
        case "QUEUED":
          return "info.main";
        case "PENDING":
          return "warning.main";
        case "COMPLETED":
          return "success.main";
        case "FAILED":
          return "error.main";
        case "SKIPPED":
          return "error.main";
        default:
          return "text.primary";
      }
    }, [file.index_status]);

    const [helpDialogOpen, setHelpDialogOpen] = useState(false);

    const handleHelpClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      setHelpDialogOpen(true);
    };

    const handleHelpDialogClose = () => {
      setHelpDialogOpen(false);
    };
    const originalIndex = useMemo(() => findFile(file.id.toString()).index, [file.id, findFile]);

    const canUpdateFile = can("UPDATE", "FILE");

    const [[{ isDragging }, drag]] = useMultiDrag({
      canDrag: !isLoading && canUpdateFile && canDrag,
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
      item: { id: file.id.toString(), originalIndex },
      type: DraggableItemTypes.File,
    });

    return (
      <>
        <StyledRow
          hover
          isDragging={isDragging}
          isLoading={isLoading}
          canDrag={!isLoading && canUpdateFile && canDrag}
          onDoubleClick={openFile}
          ref={(elem) => (canUpdateFile ? drag(elem) : null)}
          style={{
            ...(preview.display &&
            preview.itemType === DraggableItemTypes.File &&
            (preview.item as any).id === file.id.toString()
              ? preview.style
              : undefined),
          }}
        >
          <StyledCell>
            <Stack alignItems="center" direction="row" flexWrap="nowrap" gap={1}>
              {isLoading ? <CircularProgress size={24} /> : <InsertDriveFileOutlined />}
              <Typography variant="body2" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                {getItemName(file.user_fullpath)}
              </Typography>
            </Stack>
          </StyledCell>
          <StyledCell>
            <Typography variant="body2">{formatFileSize(file.size)}</Typography>
          </StyledCell>
          <StyledCell>
            <Typography variant="body2">{file.extension}</Typography>
          </StyledCell>
          <StyledCell>
            <Typography variant="body2">
              <TagsList tags={file.tags} />
            </Typography>
          </StyledCell>
          <StyledCell>
            <Typography variant="body2">{new Date(file.updated_at).toLocaleString()}</Typography>
          </StyledCell>
          <StyledCell sx={{ color: statusColor }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="body2">{t(file.index_status)}</Typography>
              {file.index_status === "SKIPPED" && (
                <IconButton size="small" onClick={handleHelpClick}>
                  <HelpIcon fontSize="small" />
                </IconButton>
              )}
            </Stack>
          </StyledCell>
          <StyledCell>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
              <IconContainer className="icon-container">
                <IconButton onClick={downloadFile}>
                  <DownloadIcon />
                </IconButton>
                {can("UPDATE", "FILE") && can("DELETE", "FILE") && (
                  <>
                    <EditFileFormDialog file={file} />
                    <IconButton onClick={deleteFile}>
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}
              </IconContainer>
            </Stack>
          </StyledCell>
        </StyledRow>
        <Dialog open={helpDialogOpen} onClose={handleHelpDialogClose}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            Suggerimento
            <IconButton
              aria-label="close"
              onClick={handleHelpDialogClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon color="error" />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            Una delle possibili cause per cui lo stato d'indicizzazione sia SKIPPED è la mancanza di TAG. Controlla se
            ne hai assegnato uno tramite il pulsante modifica!
          </DialogContent>
        </Dialog>
        <ResourceViewerModal open={isPDFModalOpen} onClose={handleModalClose} pdf={file} />
      </>
    );
  },
);

export default FileItem;
