/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Button, Stack, styled, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown, { Components } from "react-markdown";
import { IChatMessage, IChatSemanticSearchToolReturn, ToolType } from "../../../app/services/apiTypes";
import { BotAvatar, UserAvatar } from "./ChatAvatars";
import { RelevantDocument } from "./RelevantDocument";
import { CHAT_MESSAGE_COLOR } from "./utils";
import remarkGfm from 'remark-gfm'

interface ChatMessageProps {
  chatMessage: IChatMessage;
  openCitation: (citationId: string) => void;
  setOpenedResult: (result: IChatSemanticSearchToolReturn) => void;
  isError?: boolean;
  errorMessage?: string;
  onRetry?: () => void;
}


const MessageText = styled(Typography)(() => ({
  wordBreak: "break-word",
  overflowX: "auto", // Allow horizontal scrolling for wide messages
  maxWidth: "100%",
  "& p": {
    margin: 0,
  },
}));
const StyledTable = styled(Table)(({ theme }) => ({
  borderCollapse: 'collapse',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  width: 'auto',
  minWidth: '100%',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
  whiteSpace: 'normal',
  wordBreak: 'break-word',
}));

const StyledTableHeadCell = styled(StyledTableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.action.hover, // Optional: add a subtle background color
}));

// const TableWrapper = styled(Box)({
//   width: '100%',
//   overflowX: 'auto',
// });


const ChatMessage: FC<ChatMessageProps> = ({
  chatMessage,
  openCitation,
  setOpenedResult,
  isError = false,
  errorMessage,
  onRetry
}) => {
  const { t } = useTranslation();

  // Debug logging to help troubleshoot error display
  useEffect(() => {
    if (isError) {
      console.log("Message with error status:", {
        messageId: 'id' in chatMessage ? chatMessage.id : 'no-id',
        isError,
        errorMessage
      });
    }
  }, [chatMessage, isError, errorMessage]);

  const isUserMessage = chatMessage.author_type === "user";
  const isAssistantMessage = chatMessage.author_type === "agent";
  const isToolMessage = chatMessage.author_type === "tool";

  const [dots, setDots] = useState('...');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prev => (prev.length === 3 ? '.' : prev + '.'));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const markdownCustomComponents: Components = useMemo(
    () => ({
      a: ({ node, ...props }) => {
        if (!props.href) {
          return <a {...props} />;
        }

        const { href } = props;

        const matches = href.match(/^#([A-Z0-9]+)/);

        if (!matches) {
          return <a {...props} />;
        }

        const citationId = matches[1];

        return (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              openCitation(citationId);
            }}
          >
            {props.children}
          </a>
        );
      },
      table: ({ children }) => (
        <StyledTable>{children}</StyledTable>
      ),
      thead: ({ children }) => <TableHead>{children}</TableHead>,
      tbody: ({ children }) => <TableBody>{children}</TableBody>,
      tr: ({ children }) => <TableRow>{children}</TableRow>,
      th: ({ children }) => <StyledTableHeadCell component="th">{children}</StyledTableHeadCell>,
      td: ({ children }) => <StyledTableCell>{children}</StyledTableCell>,
    }),
    [openCitation],
  );

  return isToolMessage ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        mx: {
          xs: -2,
          sm: -2,
          md: -4,
          lg: -4,
          xl: -4,
        },
      }}
    >
      {chatMessage.used_tools.map((tool, index) => (
        <Typography key={index} variant="body2" color="text.primary">
          <i>
            {t(`assistant.chatMessage.tools.${tool.type}`)}
          </i>
        </Typography>
      ))}
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: isUserMessage ? "row" : "row-reverse",
        alignItems: "flex-start",
        gap: 2,
      }}
    >
      {isUserMessage ? <UserAvatar /> : isAssistantMessage && <BotAvatar />}
      <Box
        sx={{
          bgcolor: CHAT_MESSAGE_COLOR,
          borderRadius: "10px",
          position: "relative",
          px: 3,
          py: 2,
          maxWidth: {
            xs: "calc(100% - 48px)", // Adjust based on avatar size and gap
            sm: "calc(100% - 48px)",
            md: "calc(100% - 48px)",
            lg: "calc(100% - 48px)",
            xl: "calc(100% - 48px)",
          },
          border: isError ? "1px solid #f44336" : "none",
        }}
      >
        <MessageText variant="body2" color="text.primary">
          <ReactMarkdown components={markdownCustomComponents} remarkPlugins={[remarkGfm]}>{chatMessage.body || (isError ? '' : dots)}</ReactMarkdown>
        </MessageText>

        {isError && (
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
            <Tooltip title={errorMessage || "An error occurred"}>
              <ErrorOutlineIcon color="error" />
            </Tooltip>
            <Typography variant="caption" color="error">
              {errorMessage || "An error occurred while generating the response"}
            </Typography>
            {onRetry && (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={onRetry}
                sx={{ ml: 'auto', fontWeight: 'bold' }}
              >
                Start New Chat
              </Button>
            )}
          </Box>
        )}
        <Box
          sx={{
            position: "absolute",
            width: 0,
            top: "12px",
            left: isUserMessage ? "-16px" : undefined,
            right: isAssistantMessage ? "0" : undefined,
            height: 0,
            "&::after": {
              content: '""',
              position: "absolute",
              border: "0 solid transparent",
              borderTop: `9px solid ${CHAT_MESSAGE_COLOR}`,
              borderRadius: isUserMessage ? "0 20px 0" : "20px 0 0",
              width: "15px",
              height: "30px",
              transform: isUserMessage ? "rotate(145deg)" : "rotate(-145deg)",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export { ChatMessage };
