import { IDeviceRead } from "@features/device/deviceTypes";
import { ReactNode, createContext, useContext, useState } from "react";

interface AssistantContextProps {

  relevDeviceId?: IDeviceRead["id"];
  setRelevDeviceId: (id: IDeviceRead["id"]) => void;

  // relevDocumentationIds: number;
  // setRelevDocumentationIds: (ids: number) => void;
}

const AssistantContext = createContext<AssistantContextProps | undefined>(undefined);

function AssistantProvider({ children }: { children: ReactNode }) {
  const [relevDeviceId, setRelevDeviceId] = useState<IDeviceRead["id"] | undefined>();

  // const [relevDocumentationIds, setRelevDocumentationIds] = useState<number[]>([]);

  // relevantCtx is an object that contains all the relevant context for the assistant
  const assistantCtx: AssistantContextProps = {

    relevDeviceId,
    setRelevDeviceId,

    // relevDocumentationIds,
    // setRelevDocumentationIds,
  };

  return <AssistantContext.Provider value={assistantCtx}>{children}</AssistantContext.Provider>;
}

function useAssistantContext() {
  const context = useContext(AssistantContext);
  if (context === undefined) {
    throw new Error("Must be used inside an AssistantContext Provider");
  }
  return context;
}

export { AssistantProvider, useAssistantContext };
