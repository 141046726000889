import useAuthContext from "@core/auth/AuthContext";
import { SimpleSearchBar } from "@core/input";
import { Page } from "@core/layout/page";
import YourOrgBadge from "@features/organization/YourOrgBadge";
import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteUserMutation, useGetUsersQuery } from "../../app/services/appApi";
import { withAuth } from "../../core/auth/authWrappers";
import UserFormDialog from "./userFormDialog";
import { useAuthorization } from "@core/auth/authz/useAuthorization";

const UserListPage = () => {
  const { can } = useAuthorization();
  const { t } = useTranslation();
  const authContext = useAuthContext();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });
  const [searchValue, setSearchValue] = useState("");
  const [createUserDialogOpen, setCreateUserDialogOpen] = useState(false);
  const [editUserId, setEditUserId] = useState<number | undefined>(undefined);

  const { data, isLoading } = useGetUsersQuery({
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
    search: searchValue,
  });
  const [triggerDelete, { isError, isSuccess, reset: resetDelete }] = useDeleteUserMutation();

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("notifications.modelDelete.success", { model: t("models.user") }), { variant: "success" });
      resetDelete();
    }
    if (isError) {
      enqueueSnackbar(t("notifications.modelDelete.failure", { model: t("models.user") }), { variant: "error" });
    }
  }, [isSuccess, isError, t, resetDelete]);

  const canPickOrganization =
    authContext.user?.organization.max_child_orgs !== undefined && authContext.user?.organization.max_child_orgs > 0;

  const columns = useMemo(
    (): (GridColDef & { isVisible?: boolean }) [] => [
      {
        field: "id",
        headerName: t("users.headings.id"),
        minWidth: 80,
        isVisible: can("READ", "id_user"),
      },
      {
        field: "username",
        headerName: t("users.headings.email"),
        flex: 1,
        minWidth: 200,
      },
      {
        field: "organization",
        headerName: t("users.headings.organization"),
        flex: 1,
        minWidth: 140,
        renderCell: (params) => (
          <>
            {params.row.organization?.name}
            {params.row.organization?.id === authContext.user?.organization.id && <YourOrgBadge sx={{ ml: 1 }} />}
          </>
        ),
      },
      {
        field: "role",
        headerName: t("users.headings.role"),
        flex: 1,
        minWidth: 140,
        valueGetter: (params) => t(params.row.role),
      },
      {
        field: "actions",
        type: "actions",
        minWidth: 80,
        getActions: (params) => [
          <GridActionsCellItem
            closeMenuOnClick
            showInMenu
            label={t("users.edit")}
            icon={<EditOutlined />}
            onClick={() => setEditUserId(params.row.id)}
          />,
          <GridActionsCellItem
            closeMenuOnClick
            showInMenu
            label={t("users.delete")}
            icon={<DeleteOutlineOutlined />}
            onClick={() => triggerDelete(params.row.id)}
            disabled={params.row.id === authContext.user?.id}
          />,
        ],
      },
    ],
    [t, triggerDelete, can],
  );

  const actions = (
    <>
      <SimpleSearchBar onSearch={setSearchValue} size="small" />
      <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={() => setCreateUserDialogOpen(true)}>
        {t("users.add")}
      </Button>
    </>
  );

  return (
    <>
      <Page title={t("users.title")} actions={actions}>
        <DataGrid
          disableRowSelectionOnClick
          rows={data?.results || []}
          columns={columns.filter((column) => column.isVisible !== false)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
            columns: {
              columnVisibilityModel: {
                organization: canPickOrganization,
              },
            },
          }}
          pageSizeOptions={[20, 50, 100]}
          sx={{ flex: 1, "& .MuiDataGrid-footerContainer": { flexDirection: "row-reverse" } }}
          loading={isLoading}
          paginationMode="server"
          rowCount={data?.count || 0}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </Page>
      <UserFormDialog open={createUserDialogOpen} onClose={() => setCreateUserDialogOpen(false)} />
      <UserFormDialog userId={editUserId} open={!!editUserId} onClose={() => setEditUserId(undefined)} />
    </>
  );
};

export default withAuth(UserListPage);
