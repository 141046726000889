import { useAppDispatch } from "@app/hooks";
import { ChatAuthorType, IChat, IChatMessage, Language } from "@app/services/apiTypes";
import { API_PATH, appApi, useCreateChatMessageMutation, useGetChatQuery } from "@app/services/appApi";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CHAT_UNINITIALIZED } from "./utils";

interface UseChatProps {
  relevantDeviceId?: string;
}

interface UseChatReturn {
  chat?: IChat;
  chatId: number;
  isChatUninitialized: boolean;
  resetChat: () => void;
  sendMessage: (message: string) => Promise<boolean>;
  websocketOpen: boolean;
  chatError: boolean;
  setChatError: React.Dispatch<React.SetStateAction<boolean>>;
  errorMessageId: number | null;
  errorMessage: string | null;
  clearErrorAndStartNewChat: () => void;
}

const getNewMessage = (chatId: number): IChatMessage => ({
  id: -1,
  body: "",
  author_type: ChatAuthorType.Agent,
  author: null,
  downvotes: 0,
  upvotes: 0,
  context_device: null,
  chat: { id: chatId },
  created_at: new Date().toISOString(),
});

export const useChat = ({ relevantDeviceId }: UseChatProps): UseChatReturn => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [websocketOpen, setWebsocketOpen] = useState(false);
  const [chatError, setChatError] = useState(false);
  const [errorMessageId, setErrorMessageId] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [skipRefetch, setSkipRefetch] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [createChatMessage, { data: lastChatMessage, reset: resetChatOriginal, originalArgs }] =
    useCreateChatMessageMutation();

  const chatId =
    (lastChatMessage?.author_type !== ChatAuthorType.Tool && lastChatMessage?.chat.id) ||
    originalArgs?.chatId ||
    CHAT_UNINITIALIZED;

  const isChatUninitialized = chatId === CHAT_UNINITIALIZED;

  const { data: chat, refetch: refetchChat } = useGetChatQuery(chatId, { skip: isChatUninitialized || skipRefetch });

  // Function to clear error state and start fresh chat
  const clearErrorAndStartNewChat = useCallback(() => {
    setChatError(false);
    setErrorMessageId(null);
    setErrorMessage(null);
    setSkipRefetch(false);
    resetChatOriginal();
  }, [resetChatOriginal]);

  // Modified reset function to ensure error state is cleared
  const resetChat = useCallback(() => {
    clearErrorAndStartNewChat();
  }, [clearErrorAndStartNewChat]);

  const handleWebSocketError = useCallback(
    (chatId: number, errorMessage?: string) => {
      // Clear any existing timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      // Set error state
      setChatError(true);
      setWebsocketOpen(false);
      // Skip refetching to keep the error state
      setSkipRefetch(true);

      // Find the last agent message and mark it as having an error
      dispatch(
        appApi.util.updateQueryData("getChat", chatId, (prev) => {
          const messages = [...prev.messages];
          if (messages.length > 0) {
            // Find the LAST agent message by iterating from the end of the array
            for (let i = messages.length - 1; i >= 0; i--) {
              const message = messages[i];
              if (message.author_type === ChatAuthorType.Agent) {
                // Check if it's a text message (which has an id)
                if ("id" in message) {
                  // Store the message ID that has an error
                  setErrorMessageId(message.id);
                  setErrorMessage(errorMessage || "An error occurred while generating the response");
                }
                break; // Exit the loop after finding the last agent message
              }
            }
          }
          return prev;
        }),
      );

      // Do not refetch when there's an error - we want to keep the error state
      console.log("WebSocket error handled - skipping refetch to maintain error state");
    },
    [dispatch],
  );

  const openWebsocket = useCallback(
    (chatId: number) => {
      setWebsocketOpen(true);
      setChatError(false);
      setSkipRefetch(false);

      const lorem = localStorage.getItem("dbgLoremChat") === "true" ? "true" : "false";
      const wsBaseUrl = window.location.origin.replace(/^http/, "ws") + API_PATH;
      const token = localStorage.getItem("token");
      const ws = new WebSocket(`${wsBaseUrl}chat/${chatId}/stream?lorem=${lorem}&jwt=${token}`);

      // Set a timeout for initial connection
      const connectionTimeout = setTimeout(() => {
        if (ws.readyState !== WebSocket.OPEN) {
          ws.close();
          handleWebSocketError(chatId, "Connection timeout");
        }
      }, 10000); // 10 seconds timeout for initial connection

      // Set an overall connection timeout that will trigger if the server stops responding
      // This is important for cases where the server crashes or shuts down mid-stream
      timeoutRef.current = setTimeout(() => {
        if (websocketOpen) {
          console.warn("Overall connection timeout triggered");
          ws.close();
          handleWebSocketError(chatId, "Connection lost - the server may have stopped responding");
        }
      }, 20000); // 20 seconds overall timeout as a fallback

      try {
        // Handle WebSocket errors
        ws.addEventListener("error", (event) => {
          console.error("WebSocket error:", event);
          // Clear any existing timeout
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
          }

          // Set error state immediately and handle the error
          handleWebSocketError(chatId, "Connection error - please try again");
        });

        // Handle unexpected closures
        ws.addEventListener("close", (event) => {
          clearTimeout(connectionTimeout);
          // Only treat as error if we didn't receive EOF (normal closure)
          if (websocketOpen) {
            console.error("WebSocket closed unexpectedly:", event);
            // This code will execute if connection is terminated unexpectedly (e.g. server shutdown)
            handleWebSocketError(chatId, "Connection closed unexpectedly - the server might be down");
          }
        });

        ws.addEventListener("message", (event) => {
          // Reset inactivity timeout on each message
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }

          // Set new inactivity timeout
          timeoutRef.current = setTimeout(() => {
            if (websocketOpen) {
              ws.close();
              handleWebSocketError(chatId, "Response timeout - no data received");
            }
          }, 20000); // 20 seconds of inactivity timeout

          if (event.data === "<SOF>") {
            // Start of file, append new fake message
            dispatch(
              appApi.util.updateQueryData("getChat", chatId, (prev) => ({
                ...prev,
                messages: [...prev.messages, getNewMessage(chatId)],
              })),
            );
          } else if (event.data === "<EOF>") {
            // End of file - normal completion
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
              timeoutRef.current = null;
            }
            ws.close();
            setWebsocketOpen(false);
            // Only refetch if no error
            if (!chatError) {
              refetchChat();
            }
          } else if (event.data.startsWith("<ERROR>")) {
            // Server sent an explicit error message
            const errorMessage = event.data.replace("<ERROR>", "").replace("</ERROR>", "");
            ws.close();
            handleWebSocketError(chatId, errorMessage);
          } else {
            dispatch(
              appApi.util.updateQueryData("getChat", chatId, (prev) => ({
                ...prev,
                messages: [
                  ...prev.messages.slice(0, -1),
                  {
                    ...prev.messages[prev.messages.length - 1],
                    body: event.data,
                  },
                ],
              })),
            );
          }
        });
      } catch (error) {
        console.error("Error in WebSocket setup:", error);
        clearTimeout(connectionTimeout);
        handleWebSocketError(chatId, "Failed to establish connection");
      }
    },
    [dispatch, refetchChat, websocketOpen, handleWebSocketError, chatError],
  );

  const sendMessage = useCallback(
    async (body: string) => {
      // If there's an error state active, don't allow sending messages
      if (chatError) {
        console.log("Cannot send message - chat is in error state");
        return false;
      }

      if (!relevantDeviceId) {
        return false;
      }

      let messageChatId = chatId;

      try {
        const response = await createChatMessage({
          chatId: isChatUninitialized ? undefined : chatId,
          chatMessage: {
            body,
            language: i18n.language as Language,
            context_device_id: relevantDeviceId,
          },
        });

        if ("error" in response) {
          // Set error state when API returns an error
          console.error("Chat API error:", response.error);

          // Find the appropriate error message
          let errorMsg = "Server error - please try again later";
          if ("data" in response.error && response.error.data && typeof response.error.data === "object") {
            errorMsg = (response.error.data as any).message || errorMsg;
          }

          // Set error state
          setChatError(true);
          setErrorMessage(errorMsg);

          // Find the most recent agent message to mark with an error
          dispatch(
            appApi.util.updateQueryData("getChat", chatId !== CHAT_UNINITIALIZED ? chatId : -1, (prev) => {
              const messages = [...prev.messages];

              // If we have existing messages, try to find the last agent message
              if (messages.length > 0) {
                // Find the LAST agent message by iterating from the end of the array
                for (let i = messages.length - 1; i >= 0; i--) {
                  const message = messages[i];
                  if (message.author_type === ChatAuthorType.Agent) {
                    // Check if it's a text message (which has an id)
                    if ("id" in message) {
                      // Store the message ID that has an error
                      setErrorMessageId(message.id);
                      setErrorMessage(errorMsg);
                    }
                    break; // Exit the loop after finding the last agent message
                  }
                }
                return prev;
              } else {
                // No existing messages, create a new one to show the error
                const errorChatMessage = getNewMessage(chatId !== CHAT_UNINITIALIZED ? chatId : -1);
                if ("id" in errorChatMessage) {
                  setErrorMessageId(errorChatMessage.id);
                }
                return {
                  ...prev,
                  messages: [...prev.messages, errorChatMessage],
                };
              }
            }),
          );

          return false;
        }

        // Only update the messageChatId if response has data
        if (response.data) {
          messageChatId = response.data.author_type !== ChatAuthorType.Tool ? response.data.chat.id : chatId;
        }
      } catch (error) {
        console.error("Exception in sendMessage:", error);
        setChatError(true);
        setErrorMessage("Failed to send message - server may be unavailable");
        return false;
      }

      if (messageChatId !== CHAT_UNINITIALIZED) {
        openWebsocket(messageChatId);
      }

      return true;
    },
    [chatId, createChatMessage, i18n.language, isChatUninitialized, openWebsocket, relevantDeviceId, chatError],
  );

  return useMemo(
    () => ({
      chat,
      chatId,
      isChatUninitialized,
      resetChat,
      sendMessage,
      websocketOpen,
      chatError,
      setChatError,
      errorMessageId,
      errorMessage,
      clearErrorAndStartNewChat,
    }),
    [
      chat,
      chatId,
      isChatUninitialized,
      resetChat,
      sendMessage,
      websocketOpen,
      chatError,
      setChatError,
      errorMessageId,
      errorMessage,
      clearErrorAndStartNewChat,
    ],
  );
};
